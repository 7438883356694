<template>
    <v-dialog v-model="dialog" persistent  width="800"  @input="openedHandler">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew" v-on="on" v-bind="attrs" color="green" rounded depressed class="white--text">
         <v-icon size="20">mdi-plus</v-icon> {{ $t("Add Sub Account") }}
        </v-btn>
        <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("Add Sub Account") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
                <v-text-field dense outlined :dark="$store.state.isDarkMode" :label="$t('name')" 
                        v-model="structure.name" >
                </v-text-field>
            </v-col>
            <v-col md="6">
              <AutoCompleteField outlined :label="$t('Main Account')" v-model="structure.account_id"
                endPoint="/accounting/account/auto-complete" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">
            {{ isNew ? $t("Add Sub Account") : $t('edit') }}</v-btn>
          <v-btn color="blue darken-1" text @click="clearData">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
  
  export default {
    components: {
      AutoCompleteField,
    },
    data() {
      return {
        btnLoading: false,
        dialog: false,
        structure: {
          name: null,
          account_id: null,
        },
      };
    },
    props: {
      isNew: {
        type: Boolean,
        default: true,
      },
      item: {
        type: Object,
        default: () => {
          return {};
        },
      }
    },
    methods: {
        async openedHandler(v){
            if(v){
               
                this.structure.name = this.item.name;
                this.structure.account_id = this.item.account;
              
            }
        },
      async submit() {
        try {
          this.btnLoading = true;
          if (this.isNew) {
            await axios.post("/accounting/sub-account", this.structure);
            this.$Notifications(
              this.$t('add success'),
              { rtl: true, timeout: 2000 },
              "success"
            );
          } else {
            // let params =  {
            //     name: this.structure.name,
            //     account_id: this.structure.account_id?.id, 
            // }
            await axios.put(`/accounting/sub-account/${this.item.id}`, this.structure);
            this.$Notifications(
              this.$t('edit success'),
              { rtl: true, timeout: 2000 },
              "success"
            );
          }
          this.$emit("Submitted");
          this.clearData();
        } catch (err) {
        } finally {
          this.btnLoading = false;
  
        }
      },
      clearData() {
        this.structure.name = null,
        this.structure.account_id = null,
        this.dialog = false;
      },
    },
  };
  </script>
  
  <style></style>