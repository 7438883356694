<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12" class="d-flex  pl-5">
                    <v-row>
                        <v-col md="2" v-if="returnAbility('sub_account:store')" >
                            <genSubAccount @Submitted="fetchData(1)" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="12">
                    <app-base-table @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
                        :tableOptions="tableOptions" :enableDelete="false">
                        <template slot="editFeature" slot-scope="{ row }">
                            <genSubAccount v-if="returnAbility('sub_account:update')" :is-new="false" :item="row" @Submitted="fetchData" />
                            <deleteAccount @record-deleted="fetchData(1)" class="py-2" path="accounting/sub-account" :id="row.id" />
                        </template>
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import axios from "axios";
import genSubAccount from "./genSubAccount.vue";
import deleteAccount from "../../components/core/delete.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";

export default {
    components: {
        genSubAccount,
        deleteAccount,
        AutoCompleteField,
    },
    data() {
        return {
            loading: false,
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "name", "Main Account", "settings"],
                accessTableData: [["id"], ["name"], ["account","name"]],
                loading: false,
                tableMeta: {
                    total: null,
                    page: null,
                },
            },
        };
    },
    methods: {
        async fetchData(page) {
            this.tableOptions.loading = true;
            let params = {
                size: 10,
                page: page,
            };
            const response = await axios.get("/accounting/sub-account", { params });
            if (response.status == 200) {
                console.log(response.data.data);
                this.tableOptions.tableData = response.data.data,
                this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },

    },
    created() {
        
        this.fetchData(1);
    },
};
</script>

<style></style>
